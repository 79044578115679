.searchButton {
  background-color: #274869;
}
.reconciliationButton {
  background-color: #274869;
}

.treejs .treejs-selected-node {
  background-color: #bfbfbf;
}
.treejs .treejs-checkbox {
  display: none;
}

.treejs .treejs-switcher::before {
  border-top-color: #454545;
}

.treejs .treejs-switcher:hover:before {
  border-top-color: #454545;
}

.treejs .treejs-label {
  color: #474747;
  padding: 0;
  background-color: transparent;
  text-decoration: none;
}

.treejs .treejs-label:hover {
  color: #474747;
  background-color: #bfbfbf;
}

.treejs .treejs-label.active {
  color: #474747;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.dataOutput {
  background-color: #dfebf7;
  min-height: 100vh;
  padding-top: 1em;
  word-break: break-all;
}

.dataOutputStarter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dataOutputSpinner {
  position: relative;
  top: 45%;
  left: 45%;
  width: 6rem;
  height: 6rem;
}

.errorMessageRow {
  background-color: #ff7670;
  padding: 5px 0;
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}

.errorMessage {
  color: #910600;
  text-align: center;
  margin-bottom: 0;
}

.upsideImage {
  max-width: 60%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 0.1rem;
}

.buildInfo {
  max-width: 90%;
  text-align: right;
  color: #2f302a;
  display: block;
  font-size: xx-small;
  padding-top: 0.1rem;
}

.prodURL {
  text-align: left;
  color: #2f302a;
  display: block;
  font-size: small;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 1rem;
}

.populateVinSearchButton {
  width: 10rem;
}

.separator {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  margin-top: 5em;
  padding: 1em 0;
}

