.runDiagnosticCheck {
    background-color: #274869;
}
.icon {
    margin-left: 7px;
    font-size: 18px;
}

.shadow-box {
    width: 100%;
    height:auto;
    background-color: #dfebf7;
    border: 1px solid #ededed;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 2px;
  }

  