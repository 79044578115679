/* Common Section */
.background-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* Grant Exception Modal component section */
.modal {
  font-family: "Helvetica", Arial, sans-serif;
}

.show {
  display: block;
}

.modal .shadow-box {
  position: absolute;
  top: 15%;
  left: 40%;
  width: 20rem;
  height: auto;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
}

.modal .section-separator {
  padding: 5px 0px;
}

.modal .header {
  font-size: 16px;
  word-spacing: 1px;
  color: #274869;
  font-weight: 500;
  padding: 0px 2px;
}

.modal .warning-block {
  display: flex;
}

.modal .warning-block .icon {
  color: #fdb11b;
  margin-left: 0px;
  font-size: 16px;
}

.modal .warning-block .text {
  font-size: 13px;
  font-weight: 640;
  padding-top: 6px;
  padding-left: 5px;
  line-height: 1.1;
}

.modal .body-text {
  font-size: 15px;
}

.modal .buttons {
  float: right;
  padding: 2px 5px;
}

.modal .buttons .cancel-btn {
  font-size: 14px;
  border: none;
  background: none;
  color: #0a68c7;
  font-weight: 600;
  margin-right: 5px;
}

.modal .buttons .cancel-btn:hover {
  font-size: 13px;
  font-weight: 500;
  border: 1px solid #0a68c7;
  background: #274869;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
}

.modal .buttons .grant-exception-btn {
  font-size: 13px;
  border: 1px solid #0a68c7;
  background: #0a68c7;
  color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  margin-left: 5px;
}

.modal .buttons .grant-exception-btn:hover {
  background: #274869;
}

/* Grant Exception Panel Componet section */
.grant-exception-submit-btn {
  background-color: #274869;
}

/* Loader component section */
.loader .loading-indicator {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 6rem;
  height: 6rem;
}

/* Status Toast component section */
.status-toast {
  display: flex;
  align-items: center;
  position: fixed;
  padding: 15px 0;
  padding-left: 15px;
  border-radius: 5px;
  background: white;
  width: 25em;
  z-index: 999;
  top: 5%;
  right: 0.05%;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.status-toast.success-border-outline {
  border: 1.5px solid #066106;
  border-left-width: 10px;
}

.status-toast.error-border-outline {
  border: 1.5px solid #c6303e;
  border-left-width: 10px;
}

.status-toast.hidden {
  opacity: 0;
}

.status-toast .icon {
  margin-right: 15px;
  font-size: 18px;
}

.status-toast h2 {
  font-size: 16px;
  margin-bottom: 0;
}

.status-toast p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}
